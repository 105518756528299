<template>
    <div class="auth-wrapper px-2">
        <b-modal
      id="modal-scrollable"
      scrollable
      title="使用條款（包括有關私隱政策，免責聲明及版權的條款）"
      size="lg"
      ok-title="關閉"
      ok-only
    >
      <b-card-text
        v-for="(content,index) in scrollContent"
        :key="index"
      >
        {{ content }}
      </b-card-text>
    </b-modal>
    <div class="auth-inner py-2" v-if="success === true">
        <h2 class="brand-text text-primary ml-1 text-center">
              登記成功<br><br> 你的會員號碼 : {{ member_code }}
        </h2>
    </div>
      <div class="auth-inner py-2" >
        <!-- Register v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <h2 class="brand-text text-primary ml-1">
             
            </h2>
          </b-link>
  
          <b-card-title class="mb-1">
            🖊 客人同意書 Client Consent Form📋
          </b-card-title>
          <b-card-text class="mb-2">
  
          </b-card-text>
  
          <!-- form -->
          <validation-observer ref="registerForm">
            <h4 class="brand-text text-primary ml-1" v-if="valid === false">Invalid Link</h4>
            <b-form>
              <b-form-group
                label="認識途徑 How do you know us?"
                label-for="zh_name"
              >
                <b-form-input
                  id="zh_name"
                  trim
                />
  
              </b-form-group>
              <label>接受宣傳資訊 Allow Promotion Message </label><br>
              <b-form-group>
                <b-form-radio-group
                style="display:flex;"
                id="btn-radios-1"
               
                size="lg"
                button-variant="outline-primary"
                :options="[{ text: '接受 Agree', value: 'agree' },{text: '不接受 Disagree', value: 'disagree' }]"
                buttons
                name="radios-btn-default"
                />
            </b-form-group>
            <b-form-group label="Form-checkbox-group inline checkboxes (default)">
            <b-form-checkbox-group     
                :options="[
                    { text: '暗啞', value: '暗啞' },
                    {text: '色斑', value: '色斑' },
                    {text: '暗瘡', value: '暗瘡' },
                    {text: '水腫', value: '水腫' },
                    {text: '黑眼圈', value: '黑眼圈' },
                    {text: '眼袋', value: '眼袋' },
                    {text: '毛孔', value: '毛孔' },
                    {text: '虎紋', value: '虎紋' }
                ]"
                name="flavour-1a"
                class="demo-inline-spacing"
            />
            </b-form-group>
            <!--     <b-form-group
                label="英文姓名 English Name"
                label-for="en_name"
              >
                <b-form-input
                  id="en_name"
                  v-model="member.en_name"
                  trim
                />
  
              </b-form-group>


              <b-form-group
                label="手提電話 Mobile"
                label-for="mobile"
              >
                <b-form-input
                  id="mobile"
                  type="number"
                  v-model="member.mobile"
                  trim
                />
  
              </b-form-group> -->
    <!--             <label> 手提電話 Mobile</label><br>
              <b-input-group class="mb-1">
        <b-form-input v-model="member.mobile"/>
     
      </b-input-group>
      <b-alert
      variant="warning"
      :show="sent_code"
    >
      <div class="alert-body">
      {{message}}
      </div>
    </b-alert>
      <b-form-group
                label="驗證碼 Verification code"
                label-for="code"
                v-if="sent_code === true"
              >
                <b-form-input
                  id="code"
                  v-model="member.code"
                  trim
                />
  
              </b-form-group>



              <b-form-group
                label="聯絡地址 Contact Address"
                label-for="address"
              >
                <b-form-textarea
                  id="address"
                  v-model="member.address"
                  trim
                  rows="3"
                />
  
              </b-form-group>

              <label> 性別 Gender </label><br>
              <b-form-group>
                <b-form-radio-group
                style="display:flex;"
                id="btn-radios-1"
                v-model="member.gender"
                size="lg"
                button-variant="outline-primary"
                :options="[{ text: '男 Male', value: 'M' },{text: '女 Female', value: 'F' }]"
                buttons
                name="radios-btn-default"
                />
            </b-form-group>
               
  
             <b-form-group
               label="出生年月 Day Of Birth (YYYY-MM-DD)"
               label-for="dob"
             >
               <b-form-input
                 id="dob"
                 v-mask="'####-##-##'"
                 v-model="member.birth"
                 trim
               />
  
             </b-form-group>
  
            <b-form-group
              label="聯絡電郵 Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="member.email"
                trim
              />
  
            </b-form-group> -->
  
            <!-- <hr class="my-3"/>
         
            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                我已閱讀及同意<b-link v-b-modal.modal-scrollable>使用條款（包括有關私隱政策，免責聲明及版權的條款）</b-link>

              </b-form-checkbox>
            </b-form-group>
            <VueSignaturePad ref="signaturePad" height="300px" :options="{ dotSize: 2, throttle: 20,backgroundColor: '#eee',onBegin, onEnd }"/>
            <b-button class="mt-1" block @click="clear()" variant="outline-warning">
                清除簽名 Clear Signature
            </b-button> -->
            <!-- submit button -->
          
  <hr class="m-2">
              <b-button
                variant="primary"
                block
                type="button"
                @click="submit"
              >
                送出 Submit
              </b-button>
            </b-form>
          </validation-observer>
  
  
  
  
        </b-card>
      <!-- /Register v1 -->
      </div>
    </div>
  
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import {
    BCard, BLink, BCardTitle, BCardText, BForm,BButtonGroup,BAlert,
    BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,BFormTextarea,
    BFormRadioGroup,
    BFormCheckboxGroup,
  } from 'bootstrap-vue'
  import router from '@/router'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import { required, email } from '@validations'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  export default {
    components: {
      VuexyLogo,
      // BSV
      BCard,
      BAlert,
      BLink,
      BFormTextarea, BCardText,
      BFormRadioGroup,
    BFormCheckboxGroup,
      BCardTitle,
      router,
      BCardText,
      BButtonGroup,
      BForm,
      BButton,
      BFormInput,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BFormCheckbox,
      // validations
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        consent:null
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
    },
    created() {
     // this.get_consent()
    },
    methods: {
        get_client() {
          this.$http.get(process.env.VUE_APP_API_BASE+`/member/`+ router.currentRoute.params.member_token +`/consent/`)
            .then(res => {
                if (res.data.result === true ) {
                  this.client = res.data.client
                } 
            })
        },
        
        submit() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
            this.member.signature = data
            this.$http.post(process.env.VUE_APP_API_BASE+`/register/`+ this.client.web_key + `/`,this.member)
            .then(res => {
                if (res.data.result === true ) {
                this.success = true
                this.member_code = res.data.member_code
                } else {
                
                }
            })
        },
        clear() {
            this.$refs.signaturePad.clearSignature();
        },
        onBegin() {
        console.log('=== Begin ===');
        },
        onEnd() {
        console.log('=== End ===');
        },

        },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  